import classNames from 'classnames'

import RecentDocument from './Recent'
import SavedDocument from './Saved'

const ShortcutList = ({ active = 'savedDocuments', home, blue }) => {
  const classShortcutList = classNames('bg-primary-700 mw-600px w-100', {
    '': home
  })
  return (
    <>
      <div className={classShortcutList}>
        {active == 'recentDocuments' && <RecentDocument white blue={blue} />}
        {active == 'savedDocuments' && <SavedDocument blue={blue} />}
      </div>
    </>
  )
}

export default ShortcutList
