import classNames from 'classnames'

const Tabs = ({ tabs, onChange, active, className }) => {
  return (
    <div className={`${className} d-flex w-100 mw-600px`}>
      {tabs.map(({ slug, name, icon }) => {
        return (
          <Tab
            key={slug}
            active={active}
            slug={slug}
            name={name}
            onClick={() => onChange(slug)}
            icon={icon}
            single={tabs.length == 1}
          />
        )
      })}
    </div>
  )
}

const Tab = ({ icon, active, slug, name, onClick, single, keyChild }) => {
  let isActive = slug == active

  const tabClass = classNames(
    'py-4 gap-2 text-gray-50 fw-bold  d-flex align-items-center cursor-pointer d-flex justify-content-between border-top border-1',
    {
      'bg-primary-700 border-white': isActive,
      'bg-custom-primary border-transparent': !isActive,
      'w-100': single,
      'mw-300px': !single
    }
  )
  if (!name) return <></>
  return (
    <div className={tabClass} onClick={onClick}>
      <span className=" ms-4 fs-5 fw-bold">{name}</span>
      <div className="icon me-4 mt-n2">{icon}</div>
    </div>
  )
}

export default Tabs
