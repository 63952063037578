import { useEffect, useState } from 'react'

import classNames from 'classnames'
import _ from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import qs from 'qs'

import { Information } from '@carbon/icons-react'
import { BookmarkFilled } from '@carbon/icons-react'

import styles from '../../Search/Input/styles.module.scss'
import getCourtFlag from '../utils/getCourtFlag'
import DocumentVisitedInfo from '../VisitedInfo'

import { useAppContext } from '@/domain/App/context'
import { selfApi } from '@/services/api'
import { formatDateString } from '@/services/format/date'

import DocumentListSkeleton from './Skeleton'

const SavedDocument = () => {
  const { savedDocumentsIds, documentWasVisited } = useAppContext()

  const [documents, setDocuments] = useState(savedDocumentsIds.map(String))
  const [isLoading, setIsLoading] = useState(true)

  const handleSavedDocuments = async () => {
    const documentsIds = savedDocumentsIds.map(String)
    if (documentsIds.length == 0) return

    setIsLoading(true)

    try {
      const resDocuments = await selfApi.get(
        `/search/findMany?${qs.stringify(
          { ids: documentsIds },
          {
            arrayFormat: 'brackets'
          }
        )}`
      )
      setDocuments(resDocuments.data.records || [])
    } catch (error) {}

    setIsLoading(false)
  }

  useEffect(() => {
    handleSavedDocuments()
  }, [])

  const classDocumentItem = classNames(
    `bg-primary-700 d-flex align-items-center justify-content-center gap-4 fs-5 cursor-pointer text-gray-900 bg-hover-alpha-10`
  )

  return (
    <>
      {isLoading ? (
        <>
          {_.range(0, savedDocumentsIds.length).map(i => (
            <DocumentListSkeleton key={i} />
          ))}
        </>
      ) : (
        <>
          {documents.map(doc => {
            const [visited, visitedDate, visitedDateString] =
              documentWasVisited(doc.id)
            return (
              <div
                key={doc.id}
                className={`${classDocumentItem} ${styles.SearchDocumentItem}`}
              >
                <Link
                  href={`/documentos/${doc.id}`}
                  className={`w-100 text-gray-900 py-3 ms-2 d-flex align-items-center gap-4 justify-content-between `}
                >
                  <span className="text-white">
                    <BookmarkFilled />
                  </span>
                  <div className="d-flex flex-column gap-1 flex-fill text-white">
                    {visited ? (
                      <>
                        <span className="lh-1 fw-bold text-nowrap text-truncate w-300px">
                          {doc.name}
                        </span>

                        <DocumentVisitedInfo white id={doc.id} />
                      </>
                    ) : (
                      <>
                        <span className="lh-1 fw-bold text-nowrap text-truncate w-300px h-40px d-flex align-items-center">
                          {doc.name}
                        </span>
                      </>
                    )}
                  </div>
                  <Information
                    className={`${styles.SearchDropdownDocumentIcon} text-white`}
                  />
                  <div className="d-flex gap-2 flex-nowrap text-nowrap align-items-center text-white">
                    <Image
                      src={getCourtFlag('test')}
                      alt={doc.name || ''}
                      width={16}
                      height={16}
                      className="me-2"
                    />
                    <span>{doc.court.acronym}</span>
                    <span>|</span>
                    <span className="me-2">
                      {formatDateString(doc.publishedAt)}
                    </span>
                  </div>
                </Link>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}
SavedDocument.Card = DocumentListSkeleton
export default SavedDocument
