import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

const tips = [
  'DICA: Você pode digitar uma frase contendo uma ideia completa sobre o tema buscado. Jurismax irá realizar uma leitura prévia e retornar os resultados mais relevantes!',
  'DICA: Você ainda pode perguntar informações do inteiro teor ao chatbot como, por exemplo, se o documento trata de certa especificidade.',
  'DICA: Economize tempo lendo as ementas inteligentes geradas com os principais pontos do inteiro teor.',
  'Exemplo de busca: exclusão de sócio minoritário em sociedade anônima de capital fechado quebra de affectio societatis.',
  'Exemplo de busca: aplicação do Tema 69 para exclusão do icms da base de cálculo de outros tributos e interpretação da receita contábil e fiscal.',
  'DICA: Como a busca JurisMax é semântica, você não precisa usar conectores tipo OU / E.',
  'Exemplo de busca: reconhecimento paternidade socioafetiva post mortem com efeitos patrimoniais e sucessórios.',
  'Exemplo de busca: dano moral individual presumido decorrente de demora na prestação de serviços bancários superior ao tempo previsto em legislação específica.',
  'Exemplo de busca: suspensão da prescrição criminal enquanto julgamento de tema de repercussão geral.'
]

export const TipsCarousel = () => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      slidesPerView={1}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }}
      direction="vertical"
      className="h-100px h-md-50px"
    >
      {tips.map((tip, index) => (
        <SwiperSlide key={index}>
          <div>
            <p className="text-white fs-5">{tip}</p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
